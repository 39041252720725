@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800');
@import './constant.scss';

.form-control::-ms-clear,
input::-ms-clear,
input::-ms-reveal,
.form-control::-ms-reveal {
  display: none;
}

body {
  font-family: $bodyFont;
}

.full-width {
  width: $full-width;
}

.grey-body {
  background: $lightGreyBg;
  width: $full-width;
  float: left;
}

.full-height {
  height: 100%;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.hero_section {
  background-image: url(../images/header-bg.svg);
  background-repeat: no-repeat;
  min-height: 664px;
  background-position: top center;
  margin: 10px 0 0;
}

.text-center {
  text-align: center;
}

b,
strong {
  font-weight: 600;
}

a {
  transition: all 0.3s ease-in-out 0s;
}

h1.hero-main-heading {
  font-family: $fontFamily;
  font-size: 40px !important;
  padding: 155px 0 0 !important;
  color: $dark-grey !important;
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.heading-4 {
  font-size: 18px !important;
  text-align: center !important;
  font-weight: normal !important;
  padding: 18px 0 34px !important;
  margin: 0 !important;
}

h5 {
  font-family: $fontFamily;
}

.margin-left-3 {
  margin-left: 3px;
}

.margin-20 {
  margin-top: 20px !important;
}

.main-header {
  min-height: 75px;
}

.form-control.error input,
.file-upload-error,
.input-group.error input {
  border: 2px solid $red !important;
  border-radius: 4px;
}

.error-msg {
  margin: 4px 0 0 !important;
  padding: 0 !important;
  font-size: 12px;
  font-weight: normal !important;
  line-height: 16px;
  color: $red !important;
}

.logo {
  margin: 18px 0 17px;
  cursor: pointer;
}

.ui.header.heading-hero-section {
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  line-height: 28px;
  color: $dark-grey;
  margin: 24px 0 64px;
}

.heading-2 {
  font-size: 32px !important;
  color: $dark-grey !important;
  line-height: 40px !important;
  font-family: $fontFamily;
  font-weight: bold;
}

.heading-3 {
  font-size: 28px !important;
  color: $dark-grey !important;
  line-height: 40px !important;
  font-family: $fontFamily;
}

.discover-section {
  margin: 124px 0 0;
}

.discover-section .grid .discover-widget img {
  margin: 0 auto;
}

.workplace-section .ui.grid {
  justify-content: center;
}

.heading-5 {
  font-size: 24px !important;
  color: $dark-grey !important;
  line-height: 40px !important;
  font-family: $fontFamily;
  font-weight: 500 !important;
  text-align: left !important;
}

#root,
.root {
  min-height: 100%;
}

.post-content {
  color: $dark-grey;
  font-size: 16px;
  line-height: 24px;
  text-align: left !important;
}

.discover-widget .post-content,
.discover-widget .heading-5 {
  padding: 0 30px 0 40px !important;
}

.discover-widget {
  margin-top: 45px !important;
}

.workplace-section {
  margin: 140px 0 140px !important;
}

.purple-text {
  color: $blue !important;
}

.font-weight-500 {
  font-weight: 500px !important;
}

.discover-widget .font-weight-500 {
  font-weight: 500px !important;
  margin-bottom: 16px !important;
}

.content-align-center {
  text-align: center !important;
}

.no-padding {
  padding: 0 !important;
}

.text {
  font-size: 16px !important;
  line-height: 24px !important;
}

.workspace_01,
.workspace_02,
.workspace_03 {
  border: 4px solid $blue;
  border-radius: 50%;
  width: 196px;
  height: 196px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  background: $white;
}

.workspace_01 img,
.workspace_02 img,
.workspace_03 img {
  margin: 0 !important;
}

.workspace_01 {
  float: right;
}
.workspace_01::after {
  content: '';
  position: absolute;
  background: $blue;
  width: 115px;
  height: 4px;
  top: 50%;
  right: -115px;
}
.workspace_01::before {
  content: '';
  position: absolute;
  background: $blue;
  width: 16px;
  height: 16px;
  top: 47%;
  right: -11px;
  border-radius: 50%;
}
.workspace_03::before {
  content: '';
  position: absolute;
  background: $blue;
  width: 115px;
  height: 4px;
  top: 50%;
  left: -115px;
}
.workspace_03::after {
  content: '';
  position: absolute;
  background: $blue;
  width: 16px;
  height: 16px;
  top: 47%;
  left: -11px;
  border-radius: 50%;
}
.workspace_03 {
  float: left;
}
.workspace_02::before {
  content: '';
  position: absolute;
  background: $blue;
  width: 16px;
  height: 16px;
  top: 47%;
  border-radius: 50%;
  left: -11px;
}
.workspace_02::after {
  content: '';
  position: absolute;
  background: $blue;
  width: 16px;
  height: 16px;
  top: 47%;
  border-radius: 50%;
  right: -11px;
}

.yoga-point {
  margin: 0 0 14px;
  padding: 0;
}

.yoga-point li {
  list-style: none;
  position: relative;
  padding: 0 20px 10px;
  font-weight: 600;
  font-size: 16px;
}

.yoga-point li::after {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  background: $dark-grey;
  border-radius: 50%;
  left: 0;
  top: 6px;
}

.yoga-section {
  margin: 80px 0 70px;
}

.yoga-section .grey-column .text,
.meditation-section .grey-column .text {
  width: 496px;
}

.detail-link {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $blue;
  position: relative;
}

.detail-link::after {
  width: 122px;
  border: 1px solid $blue;
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
}

.detail-link:hover::after {
  border: 1px solid $dark-grey;
}

.detail-link:hover {
  color: $dark-grey;
  text-decoration: none;
}
.cookieConsent {
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid #eaf0f6;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px 16px 16px;
  z-index: 99999999;
  .cookie-text {
    font-size: 12px;
    color: #3c3e44;
  }
  .detail-link-cookie {
    font-size: 12px;
    font-weight: 300;
    color: $blue;
    position: relative;
  }
  .detail-link-cookie:hover {
    color: $blue;
    text-decoration: underline;
  }

  button {
    cursor: pointer;
    margin-top: -20px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 12px;
    border-radius: 24px;
    padding: 5px 10px;
    background: transparent;
    border: 2px solid #36c2d9;
    font-weight: normal;
    color: #36c2d9;
    height: auto;
    display: inline-block;
    width: auto;
    font-family: 'univia-pro', sans-serif !important;
  }
  button:hover {
    background: rgba(108, 34, 227, 0.15);
  }
  button:last-child {
    margin-right: 300px;
    float: right;
  }
}

.team-member-section {
  position: relative;
  z-index: 9;
}

.team-member-section::after {
  background-image: url('../images/quote.svg');
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 240px;
  top: -10px;
  background-position: center;
  z-index: -1;
}

.team-member-section .team-members {
  color: $dark-grey;
  font-weight: 600 !important;
  margin: 0 auto !important;
  max-width: 720px;
  width: $full-width;
  padding: 0 10px;
}

.meditation-section {
  margin: 48px 0;
}
.our-vision {
  margin: 100px 0;
}
.our-vision-qa-sectoion {
  border: $dark-grey solid 2px;
  border-radius: 20px;
  padding: 0;
}
.our-vision {
  background-image: url(../images/our-vision.svg);
  background-repeat: no-repeat;
  min-height: 800px;
  background-position: top center;
  padding: 80px 0 0;
  margin: 144px 0 40px;
}

.our-vision .title {
  font-size: 24px !important;
  font-weight: 500 !important;
  border-bottom: $dark-grey solid 1px;
  padding: 31.5px 30px !important;
  font-family: $fontFamily;
  color: $lightDark !important;
  line-height: 32px;
  letter-spacing: 1px;
}

.our-vision .content {
  border-bottom: $dark-grey solid 1px;
}

.our-vision .title.active {
  border-bottom: none;
  padding-bottom: 0 !important;
}

.our-vision .content:last-child {
  border-bottom: none;
}

.our-vision .content:last-child li:last-child {
  padding: 0 0 21px 25px;
}

.our-vision .content ul {
  margin-top: 17px !important;
  margin-bottom: 5px !important;
  padding: 0 0 0 32px;
}

.ui.accordion .accordion .title .dropdown.icon:before,
.ui.accordion .title .dropdown.icon::before {
  content: '';
  background: url('../images/ic_down_32.svg') no-repeat center center !important;
  height: 40px;
  width: 18px;
  position: absolute;
}

.ui.accordion .accordion .active.title .dropdown.icon,
.ui.accordion .active.title .dropdown.icon {
  -webkit-transform: none;
  transform: none;
}

.ui.accordion .active.title .dropdown.icon::before {
  background: url('../images/ic_up_32.svg') no-repeat center center !important;
}

.our-vision .our-vision-qa-sectoion .title .dropdown.icon {
  float: right;
}

.our-vision .last-question {
  border: 0 !important;
}

.our-vision .nine .our-vision-qa-sectoion {
  max-width: 560px !important;
  margin: 16px auto 0;
}

.content li.answer-li {
  font-size: 16px !important;
  padding: 0 0 8px 25px;
  line-height: 24px;
  list-style: none;
  position: relative;
}

.content li.answer-li::before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  background-color: $dark-grey;
  left: 0;
  top: 7px;
  border-radius: 100%;
}

.heading-6 {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
}
.member-info {
  font-size: 14px !important;
  text-align: center;
  line-height: 20px !important;
  padding: 0 0 15px;
}
.team-member-name {
  font-size: 18px !important;
  text-align: center;
  line-height: 20px !important;
  display: block;
  padding: 0 0 10px;
}

.register-section {
  margin: 140px 0 80px;
}

.button {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  border-radius: 24px;
  padding: 12px 0 12px 0;
  background-color: $blue;
  text-transform: uppercase;
  font-weight: normal;
  color: $white;
  height: 48px;
  display: inline-block;
  width: 240px;
  font-family: $fontFamily;
}

.button:hover {
  color: $white;
  background-color: $darkPurple;
  text-decoration: none;
}

.ui.small-btn,
.small-btn {
  border-radius: 16px;
  background-color: $blue;
  color: $white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: $center;
  padding: 8px 16px;
  width: auto !important;
  font-family: $bodyFont !important;
  height: auto;
  margin: 0;
  text-transform: uppercase;
  &:hover {
    color: $white;
    background-color: $darkPurple;
    text-decoration: none;
  }
}

.footer-social-column .social-icon {
  display: flex !important;
  justify-content: center;
}

.social-icon {
  text-align: center !important;
  margin: 0px 0 12px;
}

.social-icon .social-icon-list {
  margin: 0 8px !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
}

.register-section.footer-social-column {
  display: flex !important;
  width: $full-width !important;
  flex-direction: column;
  padding: 50px 0 24px;
  background: $white;
  margin: 0 !important;
}

.copyright-content,
.privacy-policy {
  padding: 0 !important;
  font-size: 12px !important;
  margin: 0 0 14px !important;
  display: flex;
  justify-content: center;
  width: $full-width;
}

.footer-social-column .detail-link {
  color: $blue;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  text-decoration: none;
}

.footer-social-column .detail-link:hover {
  text-decoration: underline;
}

.grey-bg.register-section {
  margin: 64px 0 0;
}

.privacy-policy {
  color: $blue !important;
}

.left-column-spacing {
  padding-left: 50px !important;
}

.right-column-spacing {
  padding-right: 50px !important;
}

.grey-bg {
  width: $full-width;
  float: left;
  margin: 150px 0;
  background-color: $greyBg;
}

.grey-column {
  background: $greyBg;
  padding: 60px 70px !important;
  border-radius: 50px;
  position: relative;
  min-height: 515px;
}

.img-column {
  left: -133px;
  top: 60px;
  position: relative;
  max-width: inherit !important;
  border-radius: 50px;
}

.right-img-column {
  left: -133px;
  top: 60px;
  position: relative;
}

.left-img-column {
  left: 0;
  z-index: 1;
  max-width: inherit !important;
  border-radius: 50px;
}

.meditation-section .grey-column {
  padding-left: 215px !important;
}

.segment.main-header {
  padding: 0 140px;
}

.modals .register-submit-modal {
  width: 656px;
  border-radius: 12px;
  background-color: $white !important;
  @include for-mobile {
    width: $full-width;
  }
}

.register-section .ui.discover-section-heading {
  margin-bottom: 51px;
}

.social-icon-list {
  width: 40px;
  height: 40px;
  display: inline-flex !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-color: $white !important;
  border-radius: 100%;
  text-align: center;
  background-position: center center;
}

.social-icon-list:hover {
  background-color: $blue !important;
}

.twitter-icon {
  background-image: url('../images/ic_twitter.svg') !important;
}

.twitter-icon:hover {
  background-image: url('../images/ic_twitter_w.svg') !important;
}

.fb-icon {
  background-image: url('../images/ic_facebook.svg') !important;
}

.fb-icon:hover {
  background-image: url('../images/ic_facebook_w.svg') !important;
}

.insta-icon {
  background-image: url('../images/ic_insta.svg') !important;
}

.insta-icon:hover {
  background-image: url('../images/ic_insta_w.svg') !important;
}

.linkedin-icon {
  background-image: url('../images/linkedin.svg') !important;
}

.linkedin-icon:hover {
  background-image: url('../images/ic_linkedin_w.svg') !important;
}

.medium-icon {
  background-image: url('../images/ic_medium.svg') !important;
}

.medium-icon:hover {
  background-image: url('../images/ic_medium_w.svg') !important;
}

.expect-section .heading-4 {
  max-width: 576px;
  margin: 0 auto !important;
  color: $lightGrey;
}

.footer-social-column .detail-link::after {
  display: none;
}

.workplace-section .three .row .column:nth-child(2) {
  z-index: 999;
}

.full-header {
  background-color: $white;
  @extend %shadow;
  padding: 0 32px;
  position: fixed;
  z-index: 99;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  @include for-mobile {
    padding: 0 24px;
    position: static;
  }
  .ui.container {
    @include for-mobile {
      margin-left: 0 !important;
    }
  }
}

.footer-social-column .ui.column.grid {
  width: $full-width !important;
  margin: 0 auto;
}

.discover-section .ui.heading-4,
.workplace-section .ui.heading-4 {
  max-width: 544px;
  margin: 0 auto !important;
  color: $lightGrey;
}

.mobile-view-only {
  display: none !important;
}

.our-vision .heading-4 {
  max-width: 576px;
  margin: 0 auto !important;
  color: $lightGrey;
}

.viewmore {
  background: transparent !important;
  color: $blue !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 0 !important;
  border-bottom: 2px solid $blue !important;
  border-radius: 0 !important;
}

.viewmore::after {
  display: none !important;
}

.back-btn {
  float: left;
  margin: 10px 0 0;
}

.back-btn .label {
  color: $lightGrey;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  font-family: $fontFamily;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  .transparent-button {
    background-color: none;
  }
}

.back-btn a:hover {
  text-decoration: none;
}

.back-btn.rq-event a:hover {
  color: $lightGrey;
}

.travel-custom-dropdown .field .dropdown {
  width: 146px;
}

/********************Login Page CSS ****************************/
.admin-notification .field .dropdown {
  padding: 0;
  border: none;
}

.admin-notification .dropdown.icon:before {
  display: none;
}

.admin-head-col {
  display: flex;
}

.get-started-column {
  width: auto;
  display: inline-block;
  text-align: center;
  margin: 0 70px;
}

.get-started-column h5 {
  color: $blue;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  margin: 0 0 24px;
}

.get-started-column p {
  color: $dark-grey;
  font-size: 12px;
  line-height: 16px;
  margin: 16px 0 0;
}

.get-started-column .link-button {
  width: auto;
  padding: 12px 42px;
}

.get-started-column .transparent-button {
  padding: 10px 60px;
  width: auto;
}

.transparent-button {
  background: transparent;
  color: $blue;
  border: 2px solid $blue;
}

.transparent-button:hover {
  border: 2px solid $blue;
  background-color: rgba(108, 34, 227, 0.15);
  color: $blue;
}

.register-section .get-started-column {
  margin: 0 16px;
}

.register-section .get-started-column p {
  margin: 8px 0 0;
}

.dont-show {
  display: none;
}

.admin-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include for-mobile {
    min-height: 64px;
  }
}

.admin-header-container .logo {
  @include for-mobile {
    margin: 11px 0 0;
  }
}

input[type='password'] {
  font-weight: bold;
  font-size: 20px;
}

.sticky {
  position: fixed;
}

.pending {
  color: $dark-grey;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  position: relative;
  display: $flex;
  left: 18px;
  &::before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    border: 2px solid $blue;
    border-radius: 50%;
    left: -18px;
    top: 4px;
  }
}

.pending.pending-spl {
  &::before {
    border: 2px solid $green;
  }
}

.booked {
  color: $dark-grey;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  position: relative;
  left: 18px;
  display: $flex;
  &::before {
    position: absolute;
    background-color: $blue;
    content: '';
    height: 10px;
    width: 10px;
    border: 2px solid $blue;
    border-radius: 50%;
    left: -18px;
    top: 4px;
  }
}

.booked.booked-spl {
  &::before {
    background-color: $green;
    border: 2px solid $green;
  }
}

.medium-heading {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin: 0;
}

.green-text {
  color: $green;
  font-weight: 600;
}

.grey-text {
  width: $full-width;
  color: $lightGrey;
  font-size: $font-size;
  display: $flex;
  font-weight: normal;
  line-height: 18px;
  margin: 8px 0 0;
}

.no-bottom-space {
  margin-bottom: 0 !important;
}

.header-row {
  display: $flex;
  justify-content: space-between;
  align-items: center;
  .button {
    width: auto;
    padding: 10px 28px;
  }
}

.term-col {
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      padding: 0 20px;
      @include for-mobile {
        padding: 0 5px;
      }
    }
  }
}

.negative.message {
  .close.icon::before {
    background: url('../images/red_close.svg') no-repeat center center !important;
    width: 10px !important;
    height: 10px !important;
    position: absolute;
    left: 3px;
    top: 2px;
    content: '';
  }
}

.professional .date-icon {
  margin-bottom: 0;
  display: block;
}

.professional .time-icon {
  display: block;
  margin-bottom: 8px;
  padding: 0 0 0 25px;
  font-size: 14px;
}

.session-details-box {
  display: flex;
  align-items: center;
  .session-type-colum {
    margin-left: 20px;
  }
}

.session-type {
  .session-type-colum {
    .ui.image {
      display: inline-block;
      margin-left: 20px;
    }
  }
  .green-tag {
    margin-right: 20px;
  }
  .session-type-yoga {
    min-width: 130px;
    display: inline-block;
  }
}
.office-address-block {
  list-style: none;
  margin: 0;
  padding: 0;
}
.contact-point-block {
  list-style: none;
  margin: 0;
  padding: 0;
}
.billing-details-block {
  list-style: none;
  margin: 0;
  padding: 0;
}
.email-details-block {
  list-style: none;
  margin: 0;
  padding: 0;
}
.experience {
  .billing-adress-block {
    margin: 0 0 8px;
  }
  .email-address-block {
    margin: 16px 0 8px;
  }
  .normaltext {
    color: $dark-grey;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
  }
}
.event-url-color {
  color: $blue;
}
.profile-column {
  .amount-pay-block {
    min-height: 84px;
    border: 0 solid$blue;
    border-radius: 4px;
    background-color: $greyBg;
    box-shadow: inset 0 0px 0px 0 $greyWhite, inset 0 -1px 0 0 $lightWhite;
    padding: 16px;
    margin: 0 0 15px 0;
    h6 {
      margin: 0 0 16px;
    }
  }
}
.professonal-edit-column {
  .verify-btn-col {
    .cancel-btn {
      color: $lightGrey;
      background: none;
      margin: 0 30px 0;
    }
  }
}
.full-height {
  height: 100%;
}
.admin-session-listing {
  margin-bottom: 1em;
}

.showup,
.noshow {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  padding: 0 0 0 20px;
  font-size: 14px;
}

.showup-icon {
  background: url(../images/showup.svg) no-repeat 0px center;
  background-size: 14px;
}

.noshow-icon {
  background: url(../images/noshow.svg) no-repeat 0px center;
  background-size: 12px;
}
.orange-colored-heading,
.green-colored-heading,
.purple-colored-heading {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
.green-colored-heading {
  color: $green;
}
.orange-colored-heading {
  color: $orange;
}
.purple-colored-heading {
  color: $darkPurple;
}
.parnter-container {
  .slider-container {
    display: 'inline-block';
  }
  .slick-track {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    .slickd {
      width: 70% !important;
      outline: none;
    }
    .yogi-bare {
      width: 50% !important;
    }
    .partner-logo {
      max-width: 100% !important;
      outline: none;
    }
  }
  .slick-arrow-btn-right {
    background: transparent;
    border: none;
    position: absolute;
    right: 0px;
    top: 47%;
    outline: none;
    transform: rotate(90deg);
    cursor: pointer;
  }
  .slick-arrow-btn-left {
    background: transparent;
    border: none;
    position: absolute;
    left: 0px;
    top: 47%;
    outline: none;
    transform: rotate(-90deg);
    z-index: 9;
    cursor: pointer;
  }
}

.expect-section {
  margin: 124px 0 0;
}
.slider-actual-container {
  overflow: hidden !important;
}
.font14 {
  font-size: 14px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .cookieConsent {
    button {
      margin-top: 40px;
    }
    button:last-child {
      float: left;
      margin-top: 10px;
      margin-right: 0px;
    }
  }
  .img-column,
  .left-img-column {
    left: -46px;
    top: 30%;
    width: 320px;
  }

  .left-img-column {
    left: -10px;
  }

  .meditation-section .grey-column {
    padding-left: 70px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grey-column {
    padding: 34px !important;
  }

  .img-column,
  .left-img-column {
    left: -82px;
    top: 140px;
    width: 300px;
  }

  .left-img-column {
    left: -10px;
  }

  .meditation-section .grey-column {
    padding-left: 80px !important;
  }

  .yoga-section .grey-column .text,
  .meditation-section .grey-column .text {
    width: 420px;
  }

  .discover-widget .post-content,
  .discover-widget .heading-5 {
    padding: 0 30px 0 10px !important;
  }

  .discover-section {
    margin: 20px 0 0;
  }

  .grey-bg {
    margin: 80px 0;
  }

  .workplace-section {
    margin: 80px 0 80px !important;
  }

  .post-content {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .cookieConsent {
    button {
      margin-top: 40px;
    }
    button:last-child {
      float: left;
      margin-top: 10px;
      margin-right: 0px;
    }
  }
  .grey-body {
    padding-bottom: 16px;
  }

  .footer-social-column .ui.centered {
    width: $full-width !important;
  }

  .our-vision .grid .nine.wide.column,
  .team-member-section .grid .eleven.wide.column,
  .team-member-section .grid .five.wide.column {
    width: $full-width !important;
  }
}

@media (max-width: 767px) {
  .cookieConsent {
    button {
      margin-top: 40px;
    }
    button:last-child {
      float: left;
      margin-top: 10px;
    }
  }
  .login-col .button {
    font-size: 16px;
    line-height: 24px;
    border-radius: 24px;
    width: auto;
    height: auto;
    padding: 5px 20px;
  }
  .admin-session-listing {
    overflow-x: scroll;
  }
  .session-list-row .add-session-col .request-submit-btn {
    font-size: 12px !important;
    padding: 8px 8px !important;
    line-height: 16px !important;
  }
  .ui.tabular.menu .item {
    padding: 16px 10px;
  }
  .admin-main-section {
    margin-top: 0;
  }
  .employer-main-section {
    padding-top: 0 !important;
  }
  .grey-body {
    background: $white;
  }

  .grey-bg {
    margin: 80px 0;
  }

  .main-header {
    min-height: 64px;
  }

  .logo {
    width: 88px;
  }

  .hero_section {
    background: none;
    min-height: auto;
  }

  .hero_section .hero-main-heading {
    background-image: url('../images/booms1.png'), url('../images/booms2.png');
    background-repeat: no-repeat;
    background-position: top center, bottom center;
    padding: 60px 0 70px !important;
    max-width: 304px;
    font-size: 36px !important;
    line-height: 52px;
    letter-spacing: 1.5px;
    margin: 0 auto !important;
  }

  .ui.header.heading-hero-section {
    margin: 32px auto;
    max-width: 304px;
  }

  .discover-section {
    margin: 80px 0 0;
  }

  .discover-section-heading {
    max-width: 304px;
    margin: 0 auto !important;
    font-size: 28px !important;
    font-weight: bold;
    padding: 0 10px !important;
  }

  .container .ui.heading-4 {
    font-size: 16px !important;
    line-height: 24px;
    max-width: 304px;
    margin: 0 auto !important;
  }

  .mobile .discover-widget {
    margin-top: 6px !important;
  }

  .desktop-view-only {
    display: none !important;
  }

  .mobile-view-only {
    display: block !important;
  }

  .discover-mobile-content {
    margin: 16px 0 0;
  }

  .discover-mobile-content .heading-5 {
    font-size: 20px !important;
    line-height: 28px !important;
    margin: 0 0 16px;
  }

  .discover-mobile-content .column {
    margin: 0 0 24px !important;
  }

  .discover-mobile-content .column:last-child {
    margin: 0 !important;
  }

  .workplace-section {
    margin: 80px 0 80px !important;
  }

  .workspace_01,
  .workspace_02,
  .workspace_03 {
    width: 98px;
    height: 98px;
    border: 2px solid $blue;
  }

  .workspace_01 img,
  .workspace_02 img,
  .workspace_03 img {
    height: 46px;
  }

  .workspace_01::before,
  .workspace_02::before,
  .workspace_02::after,
  .workspace_03::after {
    width: 8px;
    height: 8px;
    right: -5px;
  }

  .workspace_02::before,
  .workspace_03::after {
    left: -5px;
  }

  .workspace_03::before,
  .workspace_01::after {
    height: 2px;
    top: 51%;
  }

  .workplace-section .ui.purple-text {
    margin-top: 7px;
  }

  .expect-section .heading-4 {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .grey-column {
    padding: 32px 24px !important;
    border-radius: 24px;
  }

  .grey-column .ui.header {
    margin-bottom: 24px !important;
    padding: 0 !important;
  }

  .yoga-section {
    margin: 0 0 70px;
  }

  .yoga-section .grey-column .text {
    width: $full-width;
    margin: 24px 0 0;
  }

  .yoga-point {
    margin: 14px 0 14px;
  }

  .detail-link::after {
    width: 77px;
  }

  .yoga {
    width: $full-width;
    border-radius: 10px;
  }

  .expect-section .heading-4 {
    padding: 18px 0 14px !important;
  }

  .our-vision {
    padding: 0;
    margin: 80px 0 40px;
  }

  .register-section .discover-section-heading {
    font-size: 24px !important;
    line-height: 32px !important;
    max-width: 100%;
    margin-bottom: 52px !important;
  }

  .grey-bg .register-section {
    margin: 56px 0 56px !important;
    padding: 0 28px;
  }

  .footer-social-column .ui .five.column.social-icon {
    width: $full-width !important;
  }

  .register-section.footer-social-column {
    padding: 40px 0 24px;
  }

  .social-icon {
    margin: 0px 0 18px;
  }

  .copyright-content {
    max-width: 209px;
    margin: 0 auto 16px !important;
    line-height: 16px !important;
  }

  .our-vision .heading-4 {
    font-size: 16px !important;
    line-height: 24px;
    padding-top: 24px !important;
  }

  .our-vision {
    background-image: url('../images/booms3.png'), url('../images/booms4.png');
    background-repeat: no-repeat;
    background-position: top center, bottom center;
    padding: 90px 0 70px !important;
  }

  .vision-mobile-column ul {
    padding: 0 14px;
    margin: 0 0 24px;
  }

  .vision-mobile-column h5 {
    color: $dark-grey;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 28px;
    font-family: $fontFamily;
    margin: 0 0 16px;
  }

  .vision-mobile-column ul li {
    color: $dark-grey;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    list-style: none;
    margin: 0 0 12px;
    padding: 0 0 0 20px;
  }

  .vision-mobile-column ul li::after {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background: $dark-grey;
    border-radius: 50%;
    left: 0;
    top: 8px;
  }

  .team-member-section {
    margin: 72px 0 0;
  }

  .grey-bg.register-section {
    margin: 50px 0 0;
  }

  .social-icon .social-icon-list {
    margin: 0 5px !important;
  }

  .title-bar .privacy-policy-section .discover-section-heading,
  .title-bar .privacy-policy-section {
    margin: 0 !important;
  }

  .title-bar .privacy-policy-section .discover-section-heading {
    margin: 0 24px !important;
  }

  .title-bar {
    min-height: 120px !important;
    padding: 40px 0 0 !important;
  }

  .get-started-column,
  .register-section .get-started-column {
    width: $full-width;
    margin: 0 auto;
  }

  .get-started-column:last-child {
    margin-top: 32px;
  }

  .parnter-container {
    .slick-arrow-btn-left {
      left: 0;
    }
  }

  .parnter-container {
    .slick-arrow-btn-right {
      right: 0;
    }
  }

  .expect-section {
    margin-top: 80px;
  }
  .cancel-attendance .field-group-custom {
    margin: 0 !important;
  }
}

@media (max-width: 340px) {
  .workspace_01,
  .workspace_02,
  .workspace_03 {
    width: 85px;
    height: 85px;
  }
}

.form-box {
  .row-form {
    margin-bottom: 32px;
    &.row-form-space-small {
      margin-bottom: 17px;
    }
  }
  label {
    margin-bottom: 18px;
    margin-left: 0;
    color: $brown;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    display: block;
    &.label-small {
      margin-bottom: 5px;
      font-size: 12px;
    }
    &.no-space {
      margin-bottom: 0;
    }
  }
  .sublabel {
    margin: 8px 0 18px 0;
    color: $lightGrey;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 18px;
  }
  input {
    height: 48px;
    padding-left: 16px;
    border: 1px solid $greyWhite;
    border-radius: 4px;
    background-color: $white;
    color: $brown;
    font-size: 16px;
    font-family: Montserrat;
    line-height: 24px;
    outline: none;
    &.error {
      border: 2px solid $red;
    }
    &::placeholder {
      color: $greyWhite;
    }
    &:focus {
      border: 2px solid $blue;
    }
  }
  input[type='text'] {
    width: 100%;
  }
  .checkboxes-in-one-row {
    display: flex;
    flex-direction: row;
    .checkbox-radio-box {
      margin-left: 33px;
      margin-bottom: 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .checkbox-radio-box {
    margin-bottom: 15px;
    color: $brown;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    .checkbox-radio {
      width: 21px;
      height: 21px;
      margin-right: 10px;
      border: 1px solid $greyWhite;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      top: 1px;
      &.checked {
        border: none;
        &:before {
          width: 21px;
          height: 21px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background: $white;
          border: 6px solid $blue;
          border-radius: 50%;
          display: inline-block;
        }
      }
      input {
        width: 21px;
        height: 21px;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
  .checkbox-normal {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .checkbox-normal-input {
      width: 21px;
      height: 21px;
      border: 1px solid $greyWhite;
      border-radius: 4px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &.checked {
        background-color: $blue;
        border: 1px solid $blue;
        &:before {
          content: ' ';
          width: 14px;
          height: 11px;
          background-image: url(../images/ic_check_w.svg);
          background-repeat: no-repeat;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 5px;
        }
      }
      input {
        width: 21px;
        height: 21px;
        margin: 0;
        padding: 0;
        display: block;
        opacity: 0;
        cursor: pointer;
      }
    }
    label {
      margin: 1px 0 0 10px;
      color: $dark-grey;
      font-family: Montserrat;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .checkboxes-row {
    display: flex;
    flex-direction: row;
    .checkbox-radio-box {
      margin-bottom: 0;
    }
    .checkbox-radio {
      width: 42px;
      height: 42px;
      margin-right: 15px;
      border: none;
      border-radius: 0;
      background-repeat: no-repeat;
      &.checked {
        &:before {
          display: none;
        }
      }
      input {
        width: 42px;
        height: 42px;
      }
      &.rating-5 {
        background-image: url(../images/face5.svg);
        &.checked {
          background-image: url(../images/face5p.svg);
        }
      }
      &.rating-4 {
        background-image: url(../images/face4.svg);
        &.checked {
          background-image: url(../images/face4p.svg);
        }
      }
      &.rating-3 {
        background-image: url(../images/face3.svg);
        &.checked {
          background-image: url(../images/face3p.svg);
        }
      }
      &.rating-2 {
        background-image: url(../images/face2.svg);
        &.checked {
          background-image: url(../images/face2p.svg);
        }
      }
      &.rating-1 {
        background-image: url(../images/face1.svg);
        &.checked {
          background-image: url(../images/face1p.svg);
        }
      }
    }
  }
  .ui.selection.dropdown {
    width: 146px;
  }
  .submit-box {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    &.submit-box-noback {
      justify-content: flex-end;
    }
  }
  .ui.button.submit {
    width: 144px;
    height: 48px;
    margin: 0;
    padding: 0;
    border-radius: 24px;
    background-color: $blue;
    color: $white;
    font-family: $univiapro;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    outline: none;
    &.disabled {
      background-color: $greyWhite;
    }
  }
}

.ui.button.submit .company-rating-5 {
  background: url(../images/company-face5.svg) no-repeat;
  display: block;
  width: 50px;
  height: 42px;
  background-color: $white;
}
.company-rating-5 {
  background: url(../images/company-face5.svg) no-repeat;
  display: block;
  width: 50px;
  height: 42px;
  background-color: $white;
}
.company-rating-4 {
  background: url(../images/company-face4.svg) no-repeat;
  display: block;
  width: 50px;
  height: 42px;
  background-color: $white;
}
.company-rating-3 {
  background: url(../images/company-face3.svg) no-repeat;
  display: block;
  width: 50px;
  height: 42px;
  background-color: $white;
}
.company-rating-2 {
  background: url(../images/company-face2.svg) no-repeat;
  display: block;
  width: 50px;
  height: 42px;
  background-color: $white;
}
.company-rating-1 {
  background: url(../images/company-face1.svg) no-repeat;
  display: block;
  width: 50px;
  height: 42px;
  background-color: $white;
}

@media all and (-ms-high-contrast: none) {
  .checkbox-normal {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .checkbox-normal-input {
      width: 21px;
      height: 21px;
      border: 1px solid $greyWhite;
      border-radius: 4px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &.checked {
        background-color: $blue;
        border: 1px solid $blue;

        *::-ms-backdrop,
        &:before {
          content: ' ';
          width: 14px;
          height: 11px;
          background-image: url(../images/ic_check_w.svg);
          background-repeat: no-repeat;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0;
          padding: 0;
        } /* IE11 */
      }
    }
    input {
      width: 21px;
      height: 21px;
      margin: 0;
      padding: 0;
      display: block;
      opacity: 0;
      cursor: pointer;
    }
  }
  label {
    margin: 1px 0 0 10px;
    color: $dark-grey;
    font-family: Montserrat;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.confirm-label {
  width: auto;
  display: inline-block;
}
.confirm-label .professional_payment_status label:before {
  width: 24px;
  height: 24px;
  border: 1px solid $greyWhite !important;
  border-radius: 4px;
}
.confirm-label .professional_payment_status label:after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  background: url('../images/ic_check_24.svg') no-repeat 5px 7px;
  content: '' !important;
}
.check-payment-block .label {
  padding: 0 0 0 14px;
  color: $brown;
}
.column .box-shadow-not-require {
  box-shadow: none;
}
.modals .information-modal .content {
  padding: 0;
}
.modals .booking {
  padding: 0px;
  .actions {
    margin: 5px 0px;
  }
}
/* CSS BY SAHIL FOR Company DETAIL PAGE SA */
.next-session {
  .teacher-info {
    display: inline-block;
    .profssion-name {
      display: block;
      margin: 0 0 5px;
    }
    .time-icon {
      width: auto;
      margin: 0;
    }
    .date-icon {
      width: auto;
      margin: 0 0 0 12px;
      font-size: 12px;
    }
  }
}
.company-next-session {
  display: flex;
  align-content: center;
  align-items: center;
}

.modals .information-modal,
.models .company-modal {
  padding: 48px 32px;
  width: 608px;
  border-radius: 12px;
}

.information-modal .description {
  width: 100%;
}

.information-modal h3 {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 32px;
}

.information-modal .close.icon {
  position: absolute;
  padding: 0;
  top: 20px;
  right: 8px;
  opacity: 1;
}

.information-modal .close.icon::before {
  content: '';
  background: url(../images/ic_close.svg) no-repeat center center;
  background-size: 100%;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.information-modal .content {
  padding: 0 16px;
}

.modals .information-modal .actions {
  padding: 0;
  border: none;
  background: transparent;
  margin: 0;
}

.information-modal .actions .negative {
  background: transparent;
  color: $lightGrey;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: auto;
  padding: 0;
  &:hover,
  &:focus {
    background: transparent;
    color: $lightGrey;
  }
}

.information-modal .actions .button.positive {
  border-radius: 24px;
  background-color: $blue !important;
  width: auto;
  height: auto;
  padding: 13px 27px !important;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    background-color: $darkPurple !important;
  }
}

.information-modal {
  &__error {
    padding-top: 30px;
  }
}

.company-next-session {
  display: flex;
  align-content: center;
  align-items: center;
}
.yoga-rating {
  background-color: $green;
  height: 16px;
  border-right: $greyBg solid 1px;
}
.meditation-rating {
  background-color: $light-green;
  height: 16px;
  border-right: $greyBg solid 1px;
}
.mindfulness-rating {
  background-color: $orange;
  height: 16px;
}
.special-event-rating {
  background-color: $darkPurple;
  height: 16px;
}
.sessions-rating {
  display: flex;
  width: 100%;
}
.stats-listing {
  margin: 16px 0 0 0;
  list-style: none;
  padding: 0;
}
.stat-count {
  font-size: 20px;
}
.stats-listing {
  li {
    position: relative;
    padding: 0 0 6px 17px;
    span.service1,
    span.service2 {
      color: $green;
    }
    span.service3,
    span.service4 {
      color: $light-green;
    }
    span.service5,
    span.service6 {
      color: $orange;
    }
    span.default-color {
      float: right;
      color: $dark-grey;
    }
  }
  li.yoga-li::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 0;
    top: 5px;
    background: $green;
  }
  li.meditation-li::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 0;
    top: 5px;
    background: $light-green;
  }
  li.mindfulness-li::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 0;
    top: 5px;
    background: $orange;
  }
  li.specialevent-li::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 0;
    top: 5px;
    background: $darkPurple;
  }
}
.wrap-url-text {
  word-break: break-all;
  -ms-word-break: break-all;
}
.wrap-word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}

// CSS by Sunny for professional social icons
.profile-twitter-icon {
  background: url(../images/ic_twitter_black.svg) no-repeat 0 3px;
  @include icon-text-medium;
  word-break: break-all;
}
.profile-facebook-icon {
  background: url(../images/ic_facebook_black.svg) no-repeat 0 3px;
  @include icon-text-medium;
  word-break: break-all;
}
.profile-instagram-icon {
  background: url(../images/ic_insta_black.svg) no-repeat 0 3px;
  @include icon-text-medium;
  word-break: break-all;
}
.profile-website-icon {
  background: url(../images/ic_browser.svg) no-repeat 0 3px;
  @include icon-text-medium;
  word-break: break-all;
}
a.topmenu {
  color: $brown;
  font-size: 16px !important;
  text-decoration: none;
}

.auto-width-btn .modal-body .button {
  width: auto !important;
}

.button-cst-01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.margin0 {
  margin-bottom: 0px !important;
}

.ReactModalPortal {
  z-index: 999;
}

.user-list-section {
  width: 86% !important;
}
.sticky {
  position: unset !important;
}
.librarySlider {
  margin-left: auto;
  margin-right: auto;
  max-width: 99%;
}
.librarySlider .react-multiple-carousel__arrow {
  border-radius: 5px;
  background: #fff;
  min-width: 30px;
  min-height: 40px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
}
.librarySlider .react-multiple-carousel__arrow:hover {
  background: #fff;
}
.librarySlider .react-multiple-carousel__arrow--right {
  right: calc(4px + 1px);
}
.librarySlider .react-multiple-carousel__arrow--left {
  left: calc(4px + 1px);
}
.librarySlider .react-multiple-carousel__arrow::before {
  font-size: 16px;
  color: #000;
}
.libtitle span {
  font-size: 18px !important;
  font-weight: 600 !important;
}
