$black: #000000;
$blue: #36c2d9;
$dark-grey: #3c3e44;
$white: #ffffff;
$red: #ca1a30;
$lightRed: #f35c7c;
$lightDark: #3c3e46;
$lightGrey: #908a99;
$lightYellow: #93899b;
$darkPurple: #36c2d9;
$greyBg: #f8f7fc;
$lightGreyBg: #edecf2;
$lightWhite: #e9e6f0;
$darkGrey: #edecf1;
$greyWhite: #ccc9d1;
$darkBrown: #3b3d45;
$brown: #3c3e44;
$green: #3c9db4;
$light-green: #36c2d9;
$orange: #f9965e;
$greyLightBg: #ebeaf0;
$font-size: 12px;
$full-width: 100%;
$flex: flex;
$center: center;

%shadow {
  box-shadow: 0 2px 8px 0 $lightWhite;
}
%lightShadow {
  box-shadow: inset 1px 0 0 0 $greyWhite;
}

$fontFamily: 'univia-pro', sans-serif !important;
$bodyFont: 'Montserrat', sans-serif;
$univiapro: 'univia-pro', sans-serif !important;

@mixin table-tag-design {
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 8px;
  margin-right: 8px;
}

@mixin icon-text {
  font-size: $font-size;
  padding: 0 0 0 20px;
  width: 15px;
  height: 17px;
  background-size: contain;
  margin-right: 26px;
}

@mixin icon-text-medium {
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 25px;
  margin-bottom: 16px;
  display: inline-block;
  width: $full-width;
}

@mixin for-desktop-lg {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin for-ipad-only {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin for-tablet {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin for-mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin for-small-mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin for-small-mobile-landscape {
  @media (max-width: 375px) {
    @content;
  }
}
